import request from "@/utils/request";

// 查询套餐列表
export function getPackageList(data) {
  return request("post", "/apm/recharge/package/list", data);
}
// 新增套餐
export function goAddPackage(data) {
  return request("post", "/apm/recharge/package/create", data);
}
// 查询套餐列表
export function goUpdatePackage(data) {
  return request("post", "/apm/recharge/package/updateById", data);
}
// 充值订单查询
export function getRechargeOrder(data) {
  return request("post", "/apm/recharge/package/order/page", data);
}